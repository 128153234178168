<template>
  <div class="uk-container">
    <v-row class="uk-container">
      <h1 class="font-weight-bold" :style="{ color: this.fontWeightBold }">
        <base-arrow-back-icon @click="$router.go(-1)">{{
          rightArrowIcon
        }}</base-arrow-back-icon>

        <span>بروفايلي </span>
      </h1>
    </v-row>
    <v-row class="uk-container uk-margin-large-top">
      <v-form ref="form" lazy-validation>
        <v-container class="grey lighten-5">
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="user.firstName"
                class="pa-2"
                label="الاسم الأول"
                :rules="[(v) => !!v || 'الاسم الأول مطلوب']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="user.lastName"
                class="pa-2"
                label="الاسم الأخير"
                :rules="[(v) => !!v || 'الاسم الأخير مطلوب']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="user.phone"
                class="pa-2"
                label="رقم الجوال"
                :rules="[(v) => !!v || 'رقم الجوال مطلوب']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="user.email"
                class="pa-2"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="user.country"
                class="pa-2"
                label="البلد"
                :rules="[(v) => !!v || 'البلد مطلوب']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="user.identityNumber"
                class="pa-2"
                label="رقم الهوية"
                :rules="[(v) => !!v || 'رقم الهوية مطلوب']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-date-picker
                v-model="user.birthDate"
                label="تاريخ الميلاد"
                :rules="[(v) => !!v || 'تاريخ الميلاد مطلوب']"
              ></v-date-picker>
            </v-col>
          </v-row>
        </v-container>
        <div class="text-center">
          <v-btn color="success" class="mr-4" @click="update">
            <span> تحديث</span>
          </v-btn>
        </div>
      </v-form>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import BaseArrowBackIcon from "@/core/Base/Arrows/BaseArrowBackIcon";

export default {
  computed: {
    ...mapGetters({
      user: "getInstructorInfo",
    }),
  },
  components: { BaseArrowBackIcon },
  methods: {
    ...mapActions(["fetchInstructorInfo", "updateInstructorInfo"]),
    update() {
      let data = {
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        phone: this.user.phone,
        country: this.user.country,
        identity_number: this.user.identityNumber,
        birthday: this.user.birthDate,
      };
      this.updateInstructorInfo(data).then(() => {
        this.$router.push({ name: "instructorDashboardMain" });
      });
    },
  },
  mounted() {
    this.fetchInstructorInfo();
  },
};
</script>